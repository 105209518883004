export default [
  require('./controls/StartEvent.js').default,
  require('./controls/EndEvent.js').default,
  require('./controls/IntermediateCatchEvent.js').default,
  require('./controls/ErrorEventDefinition.js').default,

  require('./controls/ExclusiveGateway.js').default,
  require('./controls/ParallelGateway.js').default,

  require('./controls/bpiumGetRecord.js').default,
  require('./controls/bpiumFindRecords.js').default,
  require('./controls/bpiumEdit.js').default,
  require('./controls/bpiumAdd.js').default,
  require('./controls/bpiumDelete.js').default,
  require('./controls/bpiumGetCatalog.js').default,
  require('./controls/bpiumFileUpload.js').default,
  require('./controls/generateDoc.js').default,
  require('./controls/bpiumSendMessage.js').default,
  require('./controls/Ai.js').default,

  require('./controls/bpiumCalculate.js').default,
  require('./controls/webRequest.js').default,
  require('./controls/sendMail').default,
  require('./controls/getMail').default,
  require('./controls/sqlRequest.js').default,
  require('./controls/convertDoc.js').default,
  require('./controls/parser.js').default,
  require('./controls/code.js').default,
  require('./controls/setvar.js').default,
  require('./controls/runProcess.js').default,

  require('./controls/SequenceFlow.js').default,
  require('./controls/TimerEventDefinition.js').default,
  require('./controls/errorEventDefinitionSecond.js').default,
];
