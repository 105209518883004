export default (t) => ({
  element: 'bpmn:ServiceTask',
  service: 'Ai',
  title: t('script.controlls.Ai.title'),
  helplink: '',
  class: 'other',
  color: '#666B70',
  border: 2,
  icon: 'anticon-icon design-21',
  icon_scale: 0.95,
  offset_x: 0,
  offset_y: -15,
  priority: 2,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.Ai.title'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'section',
      name: t('script.controlls.Ai.connection'),
      type: 'group',
    },
    {
      id: 'service',
      name: t('script.controlls.Ai.service'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [
          {
            id: 'chatGpt',
            name: 'ChatGpt',
            color: 'A8D5C2',
          },
          {
            id: 'deepSeek',
            name: 'DeepSeek',
          },
          {
            id: 'grok',
            name: 'Grok',
          },
          {
            id: 'sonar',
            name: 'Sonar (Perplexity)',
          },
          {
            id: 'yandexGpt',
            name: 'YandexGpt',
          },
          {
            id: 'gigaChat',
            name: 'GigaChat',
          },
        ],
        type: 'select',
        map: 'input',
      },
    },
    // Фича для добавления ендпоинтов, например, генерация аудио, изображений и тд. Bpm подготовлен.
    // {
    //   id: 'enterEndpoint',
    //   name: t('script.controlls.Ai.enterEndpoint'),
    //   type: 'dropdown',
    //   readOnly: false,
    //   config: {
    //     items: [
    //       {
    //         id: 'selectEndpoint',
    //         name: t('script.common.fromList'),
    //         color: 'E7E9ED',
    //       },
    //       {
    //         id: 'writeEndpoint',
    //         name: t('script.common.fromVariables'),
    //         color: 'E7E9ED',
    //       },
    //     ],
    //     defaultValue: true,
    //     multiselect: false,
    //     defaultEmptyValue: ['selectEndpoint'],
    //     map: 'input',
    //   },
    // },
    // {
    //   id: 'userEndpoint',
    //   name: t('script.controlls.Ai.endpoint'),
    //   type: 'text',
    //   readOnly: false,
    //   config: {
    //     type: 'text',
    //     placeholder: t('script.common.valueExpression'),
    //     map: 'input',
    //   },
    //   visible: {
    //     enterEndpoint: {
    //       $include: 'writeEndpoint',
    //     },
    //   },
    // },
    // {
    //   id: 'chatGptEndpoint',
    //   name: t('script.controlls.Ai.endpoint'),
    //   type: 'radiobutton',
    //   readOnly: false,
    //   config: {
    //     items: [{ id: 'https://api.openai.com/v1/chat/completions', name: '' }],
    //     type: 'select',
    //     multiselect: false,
    //     map: 'input',
    //   },
    //   visible: {
    //     enterEndpoint: {
    //       $include: 'selectEndpoint',
    //     },
    //     service: {
    //       $include: 'chatGpt',
    //     },
    //   },
    // },
    // {
    //   id: 'deepSeekEndpoint',
    //   name: t('script.controlls.Ai.endpoint'),
    //   type: 'radiobutton',
    //   readOnly: false,
    //   config: {
    //     items: [{ id: 'https://api.deepseek.com/chat/completions', name: '' }],
    //     type: 'select',
    //     multiselect: false,
    //     map: 'input',
    //   },
    //   visible: {
    //     enterEndpoint: {
    //       $include: 'selectEndpoint',
    //     },
    //     service: {
    //       $include: 'deepSeek',
    //     },
    //   },
    // },
    // {
    //   id: 'gigaChatEndpoint',
    //   name: t('script.controlls.Ai.endpoint'),
    //   type: 'radiobutton',
    //   readOnly: false,
    //   config: {
    //     items: [{ id: 'https://gigachat.devices.sberbank.ru/api/v1/chat/completions', name: '' }],
    //     type: 'select',
    //     multiselect: false,
    //     map: 'input',
    //   },
    //   visible: {
    //     enterEndpoint: {
    //       $include: 'selectEndpoint',
    //     },
    //     service: {
    //       $include: 'gigaChat',
    //     },
    //   },
    // },
    // {
    //   id: 'sonarEndpoint',
    //   name: t('script.controlls.Ai.endpoint'),
    //   type: 'radiobutton',
    //   readOnly: false,
    //   config: {
    //     items: [{ id: 'https://api.perplexity.ai/chat/completions', name: '' }],
    //     type: 'select',
    //     multiselect: false,
    //     map: 'input',
    //   },
    //   visible: {
    //     enterEndpoint: {
    //       $include: 'selectEndpoint',
    //     },
    //     service: {
    //       $include: 'sonar',
    //     },
    //   },
    // },
    // {
    //   id: 'yandexGptEndpoint',
    //   name: t('script.controlls.Ai.endpoint'),
    //   type: 'radiobutton',
    //   readOnly: false,
    //   config: {
    //     items: [{ id: 'https://llm.api.cloud.yandex.net/foundationModels/v1/completion', name: '' }],
    //     type: 'select',
    //     multiselect: false,
    //     map: 'input',
    //   },
    //   visible: {
    //     enterEndpoint: {
    //       $include: 'selectEndpoint',
    //     },
    //     service: {
    //       $include: 'yandexGpt',
    //     },
    //   },
    // },
    // {
    //   id: 'grokEndpoint',
    //   name: t('script.controlls.Ai.endpoint'),
    //   type: 'radiobutton',
    //   readOnly: false,
    //   config: {
    //     items: [{ id: 'https://api.x.ai/v1/chat/completions', name: '' }],
    //     type: 'select',
    //     multiselect: false,
    //     map: 'input',
    //   },
    //   visible: {
    //     enterEndpoint: {
    //       $include: 'selectEndpoint',
    //     },
    //     service: {
    //       $include: 'grok',
    //     },
    //   },
    // },
    {
      id: 'enterModel',
      name: t('script.controlls.Ai.enterModel'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'selectModel',
            name: t('script.common.fromList'),
            color: 'E7E9ED',
          },
          {
            id: 'writeModel',
            name: t('script.common.fromVariables'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['selectModel'],
        map: 'input',
      },
    },
    {
      id: 'userModel',
      name: t('script.controlls.Ai.model'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        enterModel: {
          $include: 'writeModel',
        },
      },
    },
    {
      id: 'chatGptModel',
      name: t('script.controlls.Ai.model'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [
          { id: 'gpt-4', name: 'GPT-4' },
          { id: 'gpt-4-turbo', name: 'GPT-4 Turbo' },
          { id: 'gpt-4o', name: 'GPT-4 Omni' },
          { id: 'gpt-4o-search-preview', name: 'GPT-4o Search Preview' },
          { id: 'gpt-4o-mini-search-preview', name: 'GPT-4o Mini Search Preview' },
          { id: 'gpt-4o-mini', name: 'GPT-4 Omni Mini' },
          { id: 'o1', name: 'OpenAI o1' },
          { id: 'o1-mini', name: 'OpenAI o1 Mini' },
          { id: 'o1-pro', name: 'OpenAI o1 Pro' },
          { id: 'o3-mini', name: 'OpenAI o3 Mini' },
          { id: 'o3-mini-high', name: 'OpenAI o3 Mini High' },
          { id: 'gpt-4.5', name: 'GPT-4.5' },
        ],
        type: 'select',
        map: 'input',
      },
      visible: {
        service: {
          $include: 'chatGpt',
        },
        enterModel: {
          $include: 'selectModel',
        },
      },
    },
    {
      id: 'deepSeekModel',
      name: t('script.controlls.Ai.model'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [
          { id: 'deepseek-chat', name: 'DeepSeek-V3' },
          { id: 'deepseek-reasoner', name: 'DeepSeek-R1' },
        ],
        type: 'select',
        map: 'input',
      },
      visible: {
        service: {
          $include: 'deepSeek',
        },
        enterModel: {
          $include: 'selectModel',
        },
      },
    },
    {
      id: 'grokModel',
      name: t('script.controlls.Ai.model'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [
          { id: 'grok-2', name: 'Grok-2' },
          { id: 'grok-2-vision', name: 'Grok-2 Vision' },
          { id: 'grok-2-image', name: 'Grok-2 Image' },
        ],
        type: 'select',
        map: 'input',
      },
      visible: {
        service: {
          $include: 'grok',
        },
        enterModel: {
          $include: 'selectModel',
        },
      },
    },
    {
      id: 'sonarModel',
      name: t('script.controlls.Ai.model'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [
          { id: 'sonar-pro', name: 'Sonar Pro' },
          { id: 'sonar', name: 'Sonar' },
          { id: 'sonar-deep-research', name: 'Sonar Deep Research' },
          { id: 'sonar-reasoning-pro', name: 'Sonar Reasoning Pro' },
          { id: 'sonar-reasoning', name: 'Sonar Reasoning' },
        ],
        type: 'select',
        map: 'input',
      },
      visible: {
        service: {
          $include: 'sonar',
        },
        enterModel: {
          $include: 'selectModel',
        },
      },
    },
    {
      id: 'yandexGptModel',
      name: t('script.controlls.Ai.model'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [
          { id: 'yandexgpt-lite', name: 'YandexGPT Lite' },
          { id: 'yandexgpt', name: 'YandexGPT Pro' },
          { id: 'yandexgpt-32k', name: 'YandexGPT Pro 32k' },
          { id: 'llama-lite', name: 'Llama 8B\u00B2' },
          { id: 'llama', name: 'Llama 70B\u00B2' },
        ],
        type: 'select',
        map: 'input',
      },
      visible: {
        service: {
          $include: 'yandexGpt',
        },
        enterModel: {
          $include: 'selectModel',
        },
      },
    },
    {
      id: 'gigaChatModel',
      name: t('script.controlls.Ai.model'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [
          { id: 'GigaChat-2', name: 'GigaChat 2 Lite' },
          { id: 'GigaChat-2-Pro', name: 'GigaChat 2 Pro' },
          { id: 'GigaChat-2-Max', name: 'GigaChat 2 Max' },
          { id: 'GigaChat', name: 'GigaChat Lite' },
          { id: 'GigaChat-Pro', name: 'GigaChat Pro' },
          { id: 'GigaChat-Max', name: 'GigaChat Max' },
        ],
        type: 'select',
        map: 'input',
      },
      visible: {
        service: {
          $include: 'gigaChat',
        },
        enterModel: {
          $include: 'selectModel',
        },
      },
    },
    {
      id: 'folderId',
      name: t('script.controlls.Ai.folderId'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.Ai.folderIdHint'),
      config: {
        type: 'text',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        service: {
          $include: 'yandexGpt',
        },
      },
    },
    {
      id: 'apiToken',
      name: t('script.controlls.Ai.apiToken'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.Ai.apiTokenHint'),
      config: {
        type: 'text',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
    },
    {
      id: 'section1',
      name: t('script.controlls.sqlRequest.request'),
      type: 'group',
    },
    {
      id: 'instruction',
      name: t('script.controlls.Ai.instruction'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.Ai.instructionHint'),
      config: {
        placeholder: t('script.common.valueExpression'),
        defaultEmptyValue: `"${t('script.controlls.Ai.instructionDefault')}"`,
        map: 'input',
      },
    },
    {
      id: 'userInput',
      name: t('script.controlls.Ai.userInput'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'paramsSection',
      name: t('script.controlls.Ai.paramsSection'),
      type: 'group',
    },
    {
      id: 'creativity',
      name: t('script.controlls.Ai.creativity'),
      type: 'progress',
      readOnly: false,
      config: {
        map: 'input',
      },
    },
    {
      id: 'vocabulary',
      name: t('script.controlls.Ai.vocabulary'),
      type: 'progress',
      readOnly: false,
      hint: t('script.controlls.Ai.vocabularyHint'),
      config: {
        map: 'input',
      },
      visible: {
        service: {
          $in: ['chatGpt', 'deepSeek', 'grok', 'sonar', 'gigaChat'],
        },
      },
    },
    {
      id: 'chatGptReasoningEffort',
      name: t('script.controlls.Ai.reasoningEffort'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'low',
            name: t('script.common.low'),
            color: 'E7E9ED',
          },
          {
            id: 'medium',
            name: t('script.common.medium'),
            color: 'E7E9ED',
          },
          {
            id: 'high',
            name: t('script.common.high'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['medium'],
        map: 'input',
      },
      visible: {
        chatGptModel: {
          $in: ['o1', 'o3-mini'],
        },
        enterModel: {
          $include: 'selectModel',
        },
        service: {
          $in: ['chatGpt'],
        },
      },
    },
    {
      id: 'yandexGptReasoningEffort',
      name: t('script.controlls.Ai.reasoningEffort'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'low',
            name: t('script.common.low'),
            color: 'E7E9ED',
          },
          {
            id: 'high',
            name: t('script.common.high'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['low'],
        map: 'input',
      },
      visible: {
        service: {
          $include: 'yandexGpt',
        },
      },
    },
    {
      id: 'chatGptWebSearchOptions',
      name: t('script.controlls.Ai.webSearchOptions'),
      type: 'dropdown',
      readOnly: false,
      hint: t('script.controlls.Ai.webSearchOptionsHint'),
      config: {
        items: [
          {
            id: 'low',
            name: t('script.common.low'),
            color: 'E7E9ED',
          },
          {
            id: 'medium',
            name: t('script.common.medium'),
            color: 'E7E9ED',
          },
          {
            id: 'high',
            name: t('script.common.high'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['medium'],
        map: 'input',
      },
      visible: {
        chatGptModel: {
          $in: ['gpt-4o-search-preview', 'gpt-4o-mini-search-preview'],
        },
        enterModel: {
          $include: 'selectModel',
        },
        service: {
          $in: ['chatGpt'],
        },
      },
    },
    {
      id: 'sonarWebSearchOptions',
      name: t('script.controlls.Ai.webSearchOptions'),
      type: 'dropdown',
      readOnly: false,
      hint: t('script.controlls.Ai.webSearchOptionsHint'),
      config: {
        items: [
          {
            id: 'low',
            name: t('script.common.low'),
            color: 'E7E9ED',
          },
          {
            id: 'medium',
            name: t('script.common.medium'),
            color: 'E7E9ED',
          },
          {
            id: 'high',
            name: t('script.common.high'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['medium'],
        map: 'input',
      },
      visible: {
        service: {
          $in: ['sonar'],
        },
      },
    },
    {
      id: 'sonarSearchDomainFilter',
      name: t('script.controlls.Ai.searchDomainFilter'),
      type: 'text',
      hint: t('script.controlls.Ai.searchDomainFilterHint'),
      readOnly: false,
      config: {
        type: 'multiline',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
      visible: {
        service: {
          $in: ['sonar'],
        },
      },
    },
    {
      id: 'additionalParams',
      name: t('script.controlls.Ai.additionalParams'),
      type: 'pair',
      hint: t('script.controlls.Ai.additionalParamsHint'),
      readOnly: false,
      config: {
        placeholderValue: t('script.controlls.Ai.param'),
        placeholderSubValue: t('script.common.valueExpression'),
        type: 'script',
        theme: 'script',
        map: 'input',
      },
    },
    {
      id: 'resultSection',
      name: t('script.common.result'),
      type: 'group',
    },
    {
      id: 'resultType',
      name: t('script.controlls.Ai.resultType'),
      hint: t('script.controlls.Ai.resultTypeHint'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'textResult',
            name: t('script.controlls.Ai.textResult'),
            color: 'E7E9ED',
          },
          {
            id: 'fullResult',
            name: t('script.controlls.Ai.fullResult'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['textResult'],
        map: 'input',
      },
    },
    {
      id: 'output_text',
      name: t('script.controlls.Ai.outputText'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
      visible: {
        resultType: {
          $include: 'textResult',
        },
      },
    },
    {
      id: 'output_response',
      name: t('script.controlls.Ai.outputResponse'),
      type: 'text',
      hint: t('script.controlls.Ai.outputResponseHint'),
      readOnly: false,
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
      visible: {
        resultType: {
          $include: 'fullResult',
        },
      },
    },
  ],
  exits: [],
});
